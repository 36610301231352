




















































































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import { ListingStatus, Office, Profile, WebsiteLevel, MapSettings, WebsiteProfileDisplayType } from 'client-website-ts-library/types';
import { API } from 'client-website-ts-library/services';
import { AsyncView, RouteMeta } from 'client-website-ts-library/plugins';
import { ListingFilter, ProfileFilter } from 'client-website-ts-library/filters';

import Listings from '@/components/Listings.vue';
import TestimonialList from '@/components/Testimonials/TestimonialList.vue';
import Loader from '@/components/UI/Loader.vue';
import ProfileCards from '@/components/ProfileCards.vue';
import Awards from '@/components/Awards.vue';
import OfficeListingSearch from '@/components/OfficeListingSearch.vue';

const Map = () => import('client-website-ts-library/components/Map/Map.vue');

@Component({
  components: {
    Listings,
    TestimonialList,
    Loader,
    ProfileCards,
    Awards,
    OfficeListingSearch,
    Map,
  },
})
export default class OfficeDetails extends Mixins(AsyncView) {
  private office: Office | null = null;

  private profiles: Profile[] = [];

  private hasSoldListings = true;

  private hasCurrentListings = true;

  private hasTestimonials = true;

  private listingFilter: ListingFilter | null = null;

  private mapSettings: MapSettings | null = null;

  @Watch('$route.params.id')
  resolveId() {
    let id = this.$route.params.id;

    if (id === 'townsville-city') {
      id = '3a19ae4e-e72b-42d5-b332-e42737a2b20c';
    }

    if (id === 'belgian-garden---townsville') {
      id = '676ffee8-1d19-4be6-976c-b32be6ca2932';
    }

    if (id.length === 36) {
      // If the ID is 36 long we'll treat it as a guid
      this.updateOffice(id);
    } else {
      API.Slugs.Resolve(id).then((resolved) => {
        if (resolved.Type === 'Office') {
          this.updateOffice(resolved.Id);
        }
      });
    }
  }

  get directors(): Profile[] {
    return this.profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.Principal);
  }

  get commercialAgents(): Profile[] {
    return this.profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.CommercialAgent);
  }

  get salesAgents(): Profile[] {
    return this.profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.Agent);
  }

  get propertyManagers(): Profile[] {
    return this.profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.PropertyManager);
  }

  get support(): Profile[] {
    return this.profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.PersonalAssistant || p.DisplayType === WebsiteProfileDisplayType.Support);
  }

  stripHtml(html: string): string {
    const el = document.createElement('div');

    el.innerHTML = html;

    return el.innerText;
  }

  head(): RouteMeta | null {
    if (this.office === null) return null;

    let about = this.stripHtml(this.office.About);

    if (about.length > 156) {
      about = `${about.substring(0, 150)}...`;
    }

    return {
      title: `${this.office.OfficeName}`,
      description: about,
      image: this.office.WebsitePhotoPreview.Url ? this.office.WebsitePhotoPreview.Url : undefined,
      custom: [
        {
          id: 'meta',
          tag: 'meta',
          attributes: {
            name: 'robots',
            content: 'noindex,nofollow',
          },
        },
      ],
    };
  }

  updateOffice(id: string) {
    this.mapSettings = null;

    API.Offices.Get(id).then((office) => {
      this.office = office;

      if (this.listingFilter != null) {
        const filter = new ListingFilter({
          SearchLevel: WebsiteLevel.Office,
          SearchGuid: office.Id,
          Statuses: [ListingStatus.Current, ListingStatus.UnderContract],
        });

        this.handleAliases(filter);

        this.listingFilter = filter;
      }

      this.mapSettings = new MapSettings({
        Zoom: 20,
        Interactive: false,
        Padding: 80,
        Markers: [
          {
            Title: `${office.FranchiseName} ${office.OfficeName}`,
            Address: `${office.AddressLine1} ${this.office?.AddressLine2}`,
            Coordinate: office.Coordinate,
          },
        ],
      });
    });

    const profileFilter = new ProfileFilter();
    profileFilter.SearchLevel = WebsiteLevel.Office;
    profileFilter.SearchGuid = id;

    API.Profiles.Search(profileFilter, true).then((profiles) => {
      // sturt-street
      if (id === '3a19ae4e-e72b-42d5-b332-e42737a2b20c') { //
      }

      // bundock-street
      if (id === '676ffee8-1d19-4be6-976c-b32be6ca2932') {
        profiles = profiles.filter((p) => p.Id !== '3aba1628-0da4-4497-a63d-2dc5d60d3aa0' && p.Id !== 'f2ab697c-f810-4eb0-b426-6bf130f8c37a' && p.Id !== 'ee64d590-e007-4e19-9886-495c7ce7d35b'
        && p.Id !== '986548b4-3868-4df4-bcd8-380a16801468' && p.Id !== 'afc17a0d-4038-4587-9bbf-5c72c6fef068' && p.Id !== 'f17d2f82-b7c9-4b29-93f7-99b343fadd0f' && p.Id !== '58baa569-aabe-4e37-ba3e-299f77e8838d');
      }

      this.profiles = profiles;
    });

    this.$emit('updateView');
  }

  /* eslint-disable no-param-reassign */
  handleAliases(filter: ListingFilter) {
    // Alias offices
    const aliasOffices = [
      'f08cd028-2793-4599-88b1-dba1c8f27da2', // Magnetic Island
      'c3603166-658d-4056-9850-6cba69651fe9', // Brownsville North
    ];

    if (aliasOffices.indexOf(filter.SearchGuid) !== -1) {
      const profileIds = this.profiles.map((p) => p.Id);

      filter.SearchLevel = WebsiteLevel.Profile;
      filter.SearchGuid = profileIds.shift()!;
      filter.SearchGuids = profileIds;
      filter.PageSize = 21; // Larger page size for profile search
    }
  }
  /* eslint-enable */

  gotFilter(filter: ListingFilter) {
    this.handleAliases(filter);

    this.listingFilter = filter;
  }

  mounted() {
    this.resolveId();
  }
}
